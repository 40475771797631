import axios from 'axios';
import router from '../router';
import { showLoading, hideLoading } from '@/utils/loading'
import fa from "element-ui/src/locale/lang/fa";
// 全局axios请求loading加载


const BASE_URL = process.env.VUE_APP_API_URL

let headers = {
	Accept: 'application/json;charset=utf-8',
	companyKey: 'one-stand',
	'Content-Type': 'application/json ',
	'type': 'pc'
}

// axios配置参数
axios.defaults.baseURL = process.env.VUE_APP_API_URL
console.log(BASE_URL,'根目录URL')
//axios.defaults.headers = headers
// axios.defaults.timeout = 1000 * 10
axios.defaults.timeout = 5000 * 10

const whiteList = [];


// 请求拦截器
axios.interceptors.request.use(config => {
	config.withCredentials = true
	let token = localStorage.getItem("token")

	const whitle = whiteList.find(item => {return config.url.indexOf(item) != -1})

	//这里处理不同header Content-Type
	if(whitle){
		config.headers = {
			Accept: 'application/json;charset=utf-8',
			companyKey: 'one-stand',
			'Content-Type': 'application/json ',
			'type': 'pc'
		}
	}else{
		config.headers = headers
	}

	if (token) {
		config.headers.token = token
	} else {
		router.push("/login")
	}
	if(config.url.indexOf('/customPage/export') != -1||config.url.indexOf('/customPage/customLogExport') != -1) {
		config.responseType ='blob'
	}
	if (config.method === 'get') {
		config.params = {
			t: Date.parse(new Date()) / 1000,
			...config.params
		}
	}
	if (config.headers.showLoading === true) {
		showLoading()
	}

	return config
},
	error => {
		hideLoading()
		return Promise.reject(error)
	}
)

//  响应拦截器
axios.interceptors.response.use(response => {
		if(response.config.url.indexOf('/customPage/exportTxt') !== -1|| response.config.url.indexOf('/customPage/customLogExport')!==-1) {
			return {
			  fileName: response.headers['content-disposition'],
			  data: response.data
			}
		}
		if (response.data.code === 200) {
			hideLoading()
			return response.data
		}
		hideLoading()
		return response.data
},
	error => {
		hideLoading()
		if (error.response.data.code === 500) {
			router.push('/login')
		}

	}
)

export default axios
