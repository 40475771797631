<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data(){
    return{}
  },
  created(){
    let host = window.location.host;
    document.title = '基于标识的防伪码管理系统';
    console.log('app')
  }
};
</script>

<style>
  .clearfix:after {
    content: ".";
    clear: both;
    height: 0;
    visibility: hidden;
    display: block;
  }

  .clearfix {
    zoom: 1;
  }
</style>
