import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'

// create an axios instance
const service = axios.create({
  // transformRequest: [function(data) {
  //   return qs.stringify(data, { indices: true })
  // }],
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  withCredentials: true
})

// request interceptor
service.interceptors.request.use(
  config => {
    let token = localStorage.getItem("token")
    config.headers['companyKey'] = 'one-stand'
    config.headers['token'] = token
    config.headers['type'] = 'pc'
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if(response.status===401){
      return Promise.reject(new Error("未认证"))
    }
    else if (response.status === 200) {
      const res = response.data
      if (res.code !== undefined) {
        if (res.code === 2) {
          // store.dispatch('user/resetToken').then(() => {
          //   location.reload()
          // })
          //alert("dsad")
          location.href=res.data
        } else if (res.code === 200) {
          if (res.data === undefined) {
            return res
          } else {
            return res.data
          }
        } else {
          Message({
            message: res.info || res.message,
            type: 'error',
            duration: 5 * 1000
          })
          return Promise.reject(new Error(res.message || 'Error'))
        }
      } else if (res.status !== undefined) {
        if (res.status === 200) {
          return res.data || res
        }
      } else {
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
      }
    } else {
      store.dispatch('user/resetToken').then(() => {
        location.reload()
      })
    }
  },
  error => {
    console.log(JSON.stringify(error))
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

const get = function(url, data) {
  let params = ''
  for (const key in data) {
    params += key + '=' + data[key] + '&'
  }
  if(params){
    url=url + '?' + params
  }
  return service({
    url: url,
    method: 'get'
  })
}

const getUrl = function(url, data) {
  let params = ''
  for (const key in data) {
    params += key + '=' + data[key] + '&'
  }
  return service({
    url: url + '?' + params,
    method: 'get'
  })
}

const post = function(url, data) {
  return service({
    url: url,
    method: 'post',
    data
  })
}

const put = function(url, data) {
  return service({
    url: url,
    method: 'put',
    data
  })
}
const deletes = function(url, data) {
  return service({
    url: url,
    method: 'delete',
    data
  })
}

export { get, post, put, deletes, getUrl }
export default service
