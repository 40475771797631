import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/dashboard"
    },
	  {
		  path: "/down",
		  component: () => import("../pages/downPage/index"),
		  meta: { title: "APP下载" ,keepAlive: false }
	  },
    {
      path: "/",
      component: () => import("../components/Home.vue"),
      meta: { title: "home" },
      children: [
        {
          path: "dashboard",
          component: () => import("../pages/dashboard"),
          meta: { title: "工作台"  ,keepAlive: false}
        },
        /* {
					path: 'customer',
					component: () => import('../pages/customer'),
					meta: { title: '客户管理' }
				}, */
        {
          path: "customerInput",
          component: () => import("../pages/customer/customerInput.vue"),
          meta: { title: "客户录入" ,keepAlive: false }
        },
        {
          path: "customerManagement",
          component: () => import("../pages/customer/customerManagement.vue"),
          meta: { title: "客户管理" ,keepAlive: false }
        },
				{
          path: "customerDetall",
          component: () => import("../pages/customer/customerDetall.vue"),
          meta: { title: "客户详情"  ,keepAlive: false}
        },
        {
          path: "totalCustomer",
          component: () => import("../pages/customer/totalCustomer.vue"),
          meta: { title: "总客户池" ,keepAlive: false }
        },
		{
			path: 'vipManage',
			component: () => import('../pages/vipManage/vipMain'),
			meta: { title: '客户管理' ,keepAlive: false }
		},
		{
			path: 'vipRecord',
			component: () => import('../pages/vipManage/vipRecord'),
			meta: { title: '积分记录' ,keepAlive: false }
		},
		{
			path: 'vipScanRecord',
			component: () => import('../pages/vipManage/scanRecord'),
			meta: { title: '扫码记录' ,keepAlive: false }
		},
		{
			path: 'verifyRecord',
			component: () => import('../pages/vipManage/verifyRecord'),
			meta: { title: '验证记录' ,keepAlive: false }
		},
        {
          path: "visitCustomers",
          component: () => import("../pages/customer/visitCustomers.vue"),
          meta: { title: "访问客户" ,keepAlive: false }
        },
        /* 	{
					path: 'form',
					component: () => import('../pages/form'),
					meta: { title: '企业管理' }
				}, */
				{
					path: 'corporateInformation',
					component: () => import('../pages/businessManagement/corporateInformation'),
					meta: { title: '企业信息' ,keepAlive: false }
				},
				{
					path: 'enterpriseMember',
					component: () => import('../pages/businessManagement/enterpriseMember'),
					meta: { title: '企业成员' ,keepAlive: false }
				},
				{
					path: 'memberDetall',
					component: () => import('../pages/businessManagement/memberDetall'),
					meta: { title: '成员详情' ,keepAlive: false }
				},
				{
					path: 'sectorSetup',
					component: () => import('../pages/businessManagement/sectorSetup/sectorSetup'),
					meta: { title: '部门设置' ,keepAlive: false }
				},
				{
					path: 'AdministratorList',
					component: () => import('../pages/businessManagement/AdministratorList/AdministratorList'),
					meta: { title: '管理员列表' ,keepAlive: false }
				},
				{
					path: 'AdministratorDetail',
					component: () => import('../pages/businessManagement/AdministratorList/AdministratorDetail'),
					meta: { title: '管理员设置' ,keepAlive: false }
				},
				{
					path: 'launchProducts',
					component: () => import('../pages/merchandiseManagement/appointment/management'),
					meta: { title: '发布产品' ,keepAlive: false }
				},
				{
					path: 'advancedForm',
					component: () => import('../pages/businessManagement/advancedForm'),
					meta: { title: '自定义反馈表' ,keepAlive: false }
				},
				{
					path: 'dealerManage',
					component: () => import('../pages/dealerManage/main'),
					meta: { title: '经销商管理' ,keepAlive: false }
				},
				{
					path: 'publishAdvancedForm',
					name:'publishAdvancedForm',
					component: () => import('../pages/businessManagement/publishAdvancedForm'),
					meta: { title: '新增表单' ,keepAlive: false  }
				},
				{
					path: 'formDetall',
					name:'formDetall',
					component: () => import('../pages/businessManagement/formDetall'),
					meta: { title: '表单详情' ,keepAlive: false }
				},
				// {
				// 	path: 'websiteSettings',
				// 	component: () => import('../pages/businessManagement/websiteSettings'),
				// 	meta: { title: '官网设置' }
				// },
				{
					path: 'advertisingManagement',
					component: () => import('../pages/businessManagement/advertisingManagement'),
					meta: { title: '广告图管理' ,keepAlive: false }
				},
				{
					path: 'setBanner',
					component: () => import('../pages/businessManagement/setBanner'),
					meta: { title: '广告图设置' ,keepAlive: false }
				},
				{
					path: 'orderManagement',
					component: () => import('../pages/orderManagement'),
					meta: { title: '订单管理' ,keepAlive: false }
				},
		  {
			  path: 'evaluateManagement',
			  component: () => import('../pages/evaluateManagement'),
			  meta: { title: '评价管理' ,keepAlive: false }
		  },
				{
					path: 'commissionManagement',
					component: () => import('../pages/financialManagement/commissionManagement'),
					meta: { title: '佣金管理' ,keepAlive: false }
				},
			  {
				  path: 'merchantApply',
				  component: () => import('../pages/financialManagement/merchantApply'),
				  meta: { title: '商户管理' ,keepAlive: false }
			  },
		  {
			  path: 'Merchant',
			  component: () => import('../pages/financialManagement/merchant'),
			  meta: { title: '商户管理' ,keepAlive: false }
		  },
		  {
			  path: 'merchantApplyInfo',
			  name:'merchantApplyInfo',
			  component: () => import('../pages/financialManagement/components/merchantApplyInfo'),
			  meta: { title: '信息填写' ,keepAlive: false }
		  },
		  {
			  path: 'merchantSubjectInfo',
			  component: () => import('../pages/financialManagement/components/merchantSubjectInfo'),
			  meta: { title: '主体信息' ,keepAlive: false }
		  },
		  {
			  path: 'managementInfo',
			  component: () => import('../pages/financialManagement/components/managementInfo'),
			  meta: { title: '经营信息' ,keepAlive: false }
		  },
		  {
			  path: 'contactInfo',
			  component: () => import('../pages/financialManagement/components/contactInfo'),
			  meta: { title: '超级管理员信息' ,keepAlive: false }
		  },
		  {
			  path: 'bankAccountInfo',
			  component: () => import('../pages/financialManagement/components/BankAccountInfo'),
			  meta: { title: '结算账户信息' ,keepAlive: false }
		  },
		  {
			  path: 'additionInfo',
			  component: () => import('../pages/financialManagement/components/additionInfo'),
			  meta: { title: '补充材料' ,keepAlive: false }
		  },
		  {
			  path: 'settlementInfo',
			  component: () => import('../pages/financialManagement/components/settlementInfo'),
			  meta: { title: '行业资质' ,keepAlive: false }
		  },
				{
					path: 'agentManage',
					component: () => import('../pages/agentManage/agentManage'),
					meta: { title: '代理企业' ,keepAlive: false }
				},
				{
					path: 'companyDetail',
					component: () => import('../pages/agentManage/companyDetail'),
					meta: { title: '代理企业详情' ,keepAlive: false }
				},
				{
					path: 'personalCommissionDetall',
					component: () => import('../pages/financialManagement/personalCommissionDetall'),
					meta: { title: '个人佣金详情' ,keepAlive: false }
				},
				{
					path: 'moneyManagement',
					component: () => import('../pages/financialManagement/moneyManagement'),
					meta: { title: '资金管理' ,keepAlive: false }
				},
		  {
			  path: 'taskIndex',
			  component: () => import('../pages/printManage/taskIndex'),
			  meta: { title: '任务管理' ,keepAlive: false }
			},
			{
			  path: 'verManage',
			  component: () => import('../pages/verManage/index'),
			  meta: { title: '更新日志' ,keepAlive: false }
		  },
		  {
			  path: 'specialIndex',
			  component: () => import('../pages/printManage/specialIndex'),
			  meta: { title: '箱盒任务' ,keepAlive: false }
		  },
		  {
			  path: 'printIndex',
			  component: () => import('../pages/printManage/printIndex'),
			  meta: { title: '设备管理' ,keepAlive: false }
		  },
		  {
			  path: 'screenIndex',
			  component: () => import('../pages/printManage/screenIndex'),
			  meta: { title: '打印大屏' ,keepAlive: false }
		  },
				{
					path: 'management',
					component: () => import('../pages/merchandiseManagement/management'),
					meta: { title: '商品管理' ,keepAlive: false }
				},
				{
					path: 'appointmentManagement',
					component: () => import('../pages/merchandiseManagement/appointmentManagement'),
					meta: { title: '预约管理' ,keepAlive: false }
				},
				{
					path: 'noticeManagement',
					component: () => import('../pages/noticeManagement/companyNotice'),
					meta: { title: '通知管理' ,keepAlive: false }
				},
				{
					path:'distributionStatistics',
					component: () => import('../pages/marketingActivities/distribution/distributionStatistics'),
					meta: { title: '分销统计',keepAlive: false }
				},
				{
					path:'personnelApplication',
					component: () => import('../pages/marketingActivities/distribution/personnelApplication'),
					meta: { title: '人员申请'  ,keepAlive: false}
				},
				{
					path:'distributionLevel',
					component: () => import('../pages/marketingActivities/distribution/distributionLevel'),
					meta: { title: '分销等级' ,keepAlive: false }
				},
				{
					path:'distributionOrder',
					component: () => import('../pages/marketingActivities/distribution/distributionOrder'),
					meta: { title: '分销订单' ,keepAlive: false }
				},
				{
					path:'accountTransfer',
					component: () => import('../pages/marketingActivities/distribution/accountTransfer'),
					meta: { title: '到账流水' ,keepAlive: false }
				},
				{
					path:'productList',
					component: () => import('../pages/marketingActivities/joinGroup/productList'),
					meta: { title: '拼团商品列表' ,keepAlive: false }
				},
				{
					path:'listParticipants',
					component: () => import('../pages/marketingActivities/joinGroup/listParticipants'),
					meta: { title: '参团列表' ,keepAlive: false }
				},
				{
					path:'assembleOrdersInfo',
					component: () => import('../pages/marketingActivities/joinGroup/assembleOrdersInfo'),
					meta: { title: '订单详情' ,keepAlive: false }
				},
				{
					path:'addAssemble',
					component: () => import('../pages/marketingActivities/joinGroup/components/addAssemble'),
					meta: { title: '拼团配置' ,keepAlive: false }
				},
				{
					path:'spikeList',
					component: () => import('../pages/marketingActivities/spike'),
					meta: { title: '秒杀商品列表' ,keepAlive: false }
				},
				{
					path:'addSpike',
					component: () => import('../pages/marketingActivities/spike/components/addSpike'),
					meta: { title: '秒杀设置' ,keepAlive: false }
				},
				{
					path:'couponList',
					component: () => import('../pages/marketingActivities/couponManagement/couponList'),
					meta: { title: '优惠券列表' ,keepAlive: false }
				},
				{
					path:'detailCoupon',
					component: () => import('../pages/marketingActivities/couponManagement/detailCoupon'),
					meta: { title: '领取详情' ,keepAlive: false }
				},

				{
					path:'customerArticles',
					component: () => import('../pages/articleManagement/customerArticles'),
					meta: { title: '获客文章' ,keepAlive: false }
				},
				{
					path:'isDrafts',
					component: () => import('../pages/articleManagement/isDrafts'),
					meta: { title: '获客文章草稿箱' ,keepAlive: false }
				},
				{
					path:'employeeData',
					component: () => import('../pages/articleManagement/employeeData'),
					meta: { title: '员工数据' ,keepAlive: false }
				},
				{
					path:'staffDataDetail',
					component: () => import('../pages/articleManagement/components/staffDataDetail'),
					meta: { title: '员工数据' ,keepAlive: false }
				},

				{
					path:'visitorData',
					component: () => import('../pages/articleManagement/visitorData'),
					meta: { title: '访客数据' ,keepAlive: false}
				},
				{
					path:'videoManage',
					component: () => import('../pages/videoManage'),
					meta: { title: '短视频管理' ,keepAlive: false}
				},
				
				// {
				// 	path:'myNeeds',
				// 	component: () => import('../pages/addedServices/myNeeds'),
				// 	meta: { title: '我的需求',keepAlive: false }
				// },
				// {
				// 	path:'demandCheck',
				// 	component: () => import('../pages/addedServices/demandCheck'),
				// 	meta: { title: '需求审核',keepAlive: false }
				// },
				// {
				// 	path:'demandContactsCheck',
				// 	component: () => import('../pages/addedServices/demandContactsCheck'),
				// 	meta: { title: '人脉集市审核',keepAlive: false }
				// },
				// {
				// 	path:'demandProtuctsCheck',
				// 	component: () => import('../pages/addedServices/demandProtuctsCheck'),
				// 	meta: { title: '产品中心审核',keepAlive: false }
				// },
				// {
				// 	path:'demandPublishSetting',
				// 	component: () => import('../pages/addedServices/demandPublishSetting'),
				// 	meta: { title: '发布设置',keepAlive: false }
				// },
				{
					path:'demandReport',
					component: () => import('../pages/addedServices/demandReport'),
					meta: { title: '举报处理',keepAlive: false }
				},
				{
					path:'demandCustomize',
					component: () => import('../pages/addedServices/demandCustomize'),
					meta: { title: '自定义设置',keepAlive: false }
				},
				{
					path:'demandPublishForm',
					component: () => import('../pages/addedServices/components/demandPublishForm'),
					meta: { title: '发布需求',keepAlive: false }
				},
				{
					path:'posterAtlas',
					component: () => import('../pages/addedServices/sharingPromotion/posterAtlas'),
					meta: { title: '海报图集',keepAlive: false }
				},
				{
					path:'posterAtlasDetall',
					component: () => import('../pages/addedServices/sharingPromotion/posterAtlasDetall'),
					meta: { title: '海报图集详情',keepAlive: false }
				},
				{
					path:'clockData',
					component: () => import('../pages/addedServices/clockData'),
					meta: { title: '打卡数据',keepAlive: false }
				},
				{
					path:'clockDataDetall',
					component: () => import('../pages/addedServices/clockData/clockDataDetall'),
					meta: { title: '打卡详情',keepAlive: false }
				},
				{
					path:'appletConfig',
					component: () => import('../pages/miniProgram/appletConfig'),
					meta: { title: '小程序配置',keepAlive: false}
				},
				// {
				// 	path:'basicSetting',
				// 	component: () => import('../pages/miniProgram/basicSetting'),
				// 	meta: {
				// 		title: '基础设置',
				// 		keepAlive: false
				//  	}
				// },
				// {
				// 	path:'miniProgram',
				// 	component: () => import('../pages/miniProgram'),
				// 	meta: { title: '小程序配置' }
				// },
				{
					path:'mallSetting',
					component: () => import('../pages/miniProgram/mallSetting'),
					meta: { title: '商城设置',keepAlive: true }
				},
				// {
				// 	path:'mallTemplate',
				// 	component: () => import('../pages/miniProgram/mallTemplate'),
				// 	meta: { title: '商城模板设置',keepAlive: false }
				// },
				{
					path:'templateA',
					component: () => import('../pages/miniProgram/templateA'),
					meta: { title: '综合电商',keepAlive: true }
				},
				{
					path:'templateB',
					component: () => import('../pages/miniProgram/templateB'),
					meta: { title: '节日优惠',keepAlive: true }
				},
				{
					path:'templateC',
					component: () => import('../pages/miniProgram/templateC'),
					meta: { title: '日用百货',keepAlive: true }
				},
				{
					path:'templateD',
					component: () => import('../pages/miniProgram/templateD'),
					meta: { title: '数码家电',keepAlive: true }
				},

				{
					path:'redactOrAdd',
					component: () => import('../pages/goods/redactOrAdd'),
					meta: { title: '商品发布',keepAlive: false }
				},
				// 公众号相关
				{
					path:'/publicAbout',
					component: () => import('../pages/publicAbout/index'),
					meta: { title: '公众号相关',keepAlive: false }
				},
				// 模板管理添加
				{
					path:'customPage',
					component: () => import('../pages/customPage/customIndex'),
					meta: { title: '创建页面',keepAlive: false }
				},
				// 自定义模版列表
				{
					path:'customPageList',
					component: () => import('../pages/customPageList/customlistIndex'),
					meta: { title: '模版列表',keepAlive: false }
				},
				{
					path: 'pageClass',
					component: () => import('../pages/pageClass/index'),
					meta: { title: '页面类别' ,keepAlive: false }
				 },
				 {
					path: 'imgManage',
					component: () => import('../pages/pageClass/imgList'),
					meta: { title: '图片管理' ,keepAlive: false }
				 },
				// 创建页面列表
				{
					path:'createPageList',
					component: () => import('../pages/createPageList/createPageIndex'),
					meta: { title: '页面列表',keepAlive: true,isBack: false }
				},
				// 自定义操作员页面
				// {
				// 	path:'createOperatorPage',
				// 	component: () => import('../pages/operatorPage/index'),
				// 	meta: { title: '创建操作员界面',keepAlive: false }
				// },
				//  标识页面列表
				{
					path:'handlePageList',
					component: () => import('../pages/handlePageList/handlePageIndex'),
					meta: { title: '标识页面列表',keepAlive: false }
				},
				{
					path:'verPageList',
					component: () => import('../pages/verPageList/verPageIndex'),
					meta: { title: '验证记录',keepAlive: false }
				},
				{
					path: 'configHandleType',
					component: () => import('../pages/configHandleType/index.vue'),
					meta: { title: '数据模版',keepAlive: false }
				},
        {
					path: 'configHandleTypeAdd',
				    component: () => import('../pages/configHandleType/add.vue'),
					meta: { title: '新增数据模版',keepAlive: false }
				},
				{
					path: 'configTemplate',
					component: () => import('../pages/configTemplate/index.vue'),
					meta: { title: '标识模版',keepAlive: false }
				},
        {
					path: 'configTemplateAdd',
				    component: () => import('../pages/configTemplate/add.vue'),
					meta: { title: '新增标识模版',keepAlive: false }
				},
			{
				path: 'configTemplateDetail',
					component: () => import('../pages/configTemplate/detail.vue'),
				meta: { title: '标识模版详情',keepAlive: false }
			},
		//   {
		// 	  path: 'handleCustomer',
		// 	  component: () => import('../pages/handleCustomer/main'),
		// 	  meta: { title: '客户管理' ,keepAlive: false }
		//   },
		  {
			  path: 'customerHandleList',
			  component: () => import('../pages/handleCustomer/components/handleList'),
			  meta: { title: '客户标识数据' ,keepAlive: false }
		  },
			{
				path: 'pageVipRecord',
					component: () => import('../pages/vip/record.vue'),
				meta: { title: '积分扫码记录',keepAlive: false }
			},
		  {
			  path: 'ryt',
			  component: () => import('../pages/ryt/index.vue'),
			  meta: { title: '任意贴',keepAlive: false }
			},
		  {
				path: 'freight',
				component: () => import('../pages/order/freightNew'),
				meta: {title: '运费管理', keepAlive: false}
			 },
		  {
			  path: 'fitup',
			  component: () => import('../pages/shop/fitup'),
			  meta: {title: '店铺装修', keepAlive: false}
			},
			{
				path: 'newAction',
				component: () => import('../pages/order/reductionActivity'),
				meta: {title: '满减活动', keepAlive: false}
			 },
		  {
			  path: 'goodGroup',
			  component: () => import('../pages/goodGroup/index'),
			  meta: {title: '商品组管理', keepAlive: false}
		  },
				// {
				// 	path:'citlnTemplate',
				// 	component: () => import('../pages/citlnTemplateManage/index'),
				// 	meta: { title: '',keepAlive: false }
				// }
			]
		},
		// 模板管理查看
		{
			path:'/customPageShow',
			component: () => import('../pages/customPageList/customPageShow'),
			meta: { title: '页面查看',keepAlive: false }
		},
		{
			path: '/login',
			component: () => import('../pages/login'),
			meta: { title: '登录' }
		},
		{
			path: '/bindAccount',
			component: () => import('../pages/bindAccount'),
			meta: { title: '绑定账号' }
		},
		{
			path: "*",
			redirect: "/dashboard"
		}

	]
})
