import httpUtil from "@/utils/httpUtil";

/** 获取所有公司信息 */

export function getAllCompany(params,type) {
	return httpUtil.get("/company/companyList", params, type)
}

/** 切换公司 */

export function switchCompanty(params,type) {
	return httpUtil.post("/home/companySwitch", params, type)
}

/** 获取公司信息 */

export function fetchCompany(params,type) {
	return httpUtil.post("/company/getCompany", params, type)
}

/** 获取分配数量 */
export function fetchDisCount(params,type) {
	return httpUtil.get("/customPage/count", params, type)
}

/** 更新公司信息 */

export function updCompany(params,type) {
	return httpUtil.post("/company/updCompany", params, type)
}


/** 获取公司信息 */

export function fetchIndustryList(params,type) {
	return httpUtil.get("/industry/getList", params, type)
}

/***添加常用语 */
export function fetchAddCommon(params,type) {
	return httpUtil.post("/company/addCommon", params, type)
}

/***获取常用语列表 */
export function fetchGetCommonList(params,type) {
	return httpUtil.get("/company/commonList", params, type)
}

/** 获取公司成员 */
export function fetchCorporator(params,type) {
	return httpUtil.post("/businessCard/getCompanyStaffList", params, type)
}

/** 搜索员工 */
export function fetchStaffList(params,type) {
	return httpUtil.post("/businessCard/getCompanyStaffList", params, type)
}

/** 获取预约分类 */

export function fetchProductsTypeList(params,type) {
	return httpUtil.post("/products/selectProductsTypeListPc", params, type)
}

/** 获取预约列表 */

export function fetchAppointmentPage(params,type) {
	return httpUtil.post("/products/selectAppointmentPagePc", params, type)
}


/** 修改订单状态 */
export function alterAppointmentState(params,type) {
	return httpUtil.post("/products/updAppointmentState", params, type)
}


/** 查询预约商品列表状态 */
export function fetchAppointmentGoodsList(params,type) {
	return httpUtil.post("/products/selectProductsPagePc", params, type)
}

/** 修改商品排序 */
export function alterAppointmentGoodsSort(params,type) {
	return httpUtil.post("/products/moveProducts", params, type)
}


/** 查询分类 */
export function fetchProductsTypePage(params,type) {
	return httpUtil.post("/products/selectProductsTypePagePc", params, type)
}

/** 修改分类排序 */
export function alterAppointmentGoodsTypeSort(params,type) {
	return httpUtil.post("/products/moveProductsType", params, type)
}

/** 添加分类 */
export function addAppointmentGoodsType(params,type) {
	return httpUtil.post("/products/insertProductsType", params, type)
}


/** 删除分类 */
export function delProductsType(params,type) {
	return httpUtil.post("/products/delProductsType", params, type)
}


/** 获取广告图分类 */
export function fetchPhotoType(params,type) {
	return httpUtil.post("/companyPhoto/selectTypeNum", params, type)
}

/** 获取高级表单 */
export function queryPageAdvancedForm(params,type) {
	return httpUtil.post("/advancedForm/queryPageAdvancedForm", params, type)
}



/** 获取高级表单详情 */
export function queryAdvancedFormById(params,type) {
	return httpUtil.post("/advancedForm/queryAdvancedFormById", params, type)
}


/** 新增高级表单 */
export function addAdvancedForm(params,type) {
	return httpUtil.post("/advancedForm/addAdvancedForm", params, type)
}

/** 编辑高级表单 */
export function updateAdvancedForm(params,type) {
	return httpUtil.post("/advancedForm/updateAdvancedForm", params, type)
}



/** 更改表单状态 */
export function updateAdvancedFormStatus(params,type) {
	return httpUtil.post("/advancedForm/updateAdvancedFormStatus", params, type)
}

/** 获取高级表单用户数据 */
export function queryUserCommitDetail(params,type) {
	return httpUtil.post("/advancedForm/queryUserCommitDetail", params, type)
}

/** 获取成员信息*/
export function getCompanyStaffInfo(params,type) {
	return httpUtil.post("/businessCard/getCompanyStaffInfo", params, type)
}

/** 删除成员*/
export function quitCompany(params,type) { 
	// return httpUtil.post("/businessCard/quitCompany", params, type)
	return httpUtil.post("/userTask/quitCompany", params, type)
}

/**删除商品信息 */
export const delProducts = params => httpUtil.post("/products/delProducts", params)



// /** 获取高级表单用户数据 */
// export function queryUserCommitDetail(params,type) {
// 	return httpUtil.post("/advancedForm/queryUserCommitDetail", params, type)
// }

/**设置部门 */
/**查询所有员工 */
export const queryPageEmployee = async params => httpUtil.post("/department/queryPageEmployee", params);

/**查询不在部门的员工 */
export const queryNotDepart = async params => httpUtil.post("/department/queryNotDepart", params);


/**添加部门 */
export const addDepartment = async params => httpUtil.post("/department/addDepartment", params);

/**查询部门 */
export const queryPageDepartment = async params => httpUtil.post("/department/queryPageDepartment", params);

/**查询部门详情 */
export const queryDepartment = async params => httpUtil.post("/department/queryDepartment", params);


/**编辑部门成员 */
export const updateDepartment = async params => httpUtil.post("/department/updateDepartment", params);

/**解散部门 */
export const disbandDepartment = async params => httpUtil.post("/department/disbandDepartment", params);

/**删除部门 */
export const deleteDepartment = async params => httpUtil.post("/department/deleteDepartment", params);






/**设置管理员 */
/** 保存管理员*/
export const addManager = async params => httpUtil.post("/department/addManager", params);

/** 修改管理员*/
export const updateManager = async params => httpUtil.post("/department/updateManager", params);

/** 查询员工权限 */
export const queryEmpRouter = async params => httpUtil.post("/department/queryEmpRouter", params);

/** 员工详情查询 */
export const queryEmployee = async params => httpUtil.post("/department/queryEmployee", params);

/**查询管理员列表 */
export const queryPageManager = async params => httpUtil.post("/department/queryPageManager", params);

/**删除管理员 */
export const deletedManager = async params => httpUtil.post("/department/deletedManager", params);

/**修改员工 */
export const updateEmployee = async params => httpUtil.post("/department/updateEmployee", params);

/***生成名片码 */
export const getWxCodeImg = async params => httpUtil.post("/businessCard/getWxTwoCodeIndex", params);

/***更换超管 */
export const fetchChangeBoss = async params => httpUtil.post("/userTask/changeBoss", params);

/***更新通知用户 */
export const fetchUpNotice = async params => httpUtil.post("/company/upNotice", params);

/***更新通知用户回显 */
export const fetchGetNoticeList = async params => httpUtil.get("/company/noticeList", params);

/***添加经销商 */
export const fetchAddOrUpdateDeal = async params => httpUtil.post("/dealer/addOrUpdate", params);

/***经销商列表 */
export const fetchDealList = async params => httpUtil.get("/dealer/queryDealerPage", params);

/***删除经销商 */
export const fetchDeleteDeal = async params => httpUtil.post("/dealer/delete", params);
